import { Injectable, inject } from '@angular/core';
import { WINDOW } from '@shared/providers';

/**
 * Service for interacting with the browser's local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly localStorage = inject(WINDOW).localStorage;

  /**
   * Retrieves the value associated with the specified key from local storage.
   * @param key - The key to retrieve the value for.
   * @param useBase64 - Optional flag to decode the value from base64.
   * @returns The value associated with the key, or null if the key does not exist.
   */
  get<T>(key: string, useBase64 = false): T | null {
    const item = this.localStorage.getItem(key);

    if (!item) {
      return null;
    }

    const decodedItem = useBase64 ? atob(item) : item;
    return this.isJSONValid(decodedItem)
      ? (JSON.parse(decodedItem) as T)
      : (decodedItem as T);
  }

  /**
   * Sets the value associated with the specified key in local storage.
   * @param key - The key to set the value for.
   * @param value - The value to be stored.
   * @param useBase64 - Optional flag to encode the value in base64.
   */
  set(key: string, value: unknown, useBase64 = false): void {
    const stringValue = JSON.stringify(value);
    const finalValue = useBase64 ? btoa(stringValue) : stringValue;

    this.localStorage.setItem(key, finalValue);
  }

  /**
   * Deletes the value associated with the specified key from local storage.
   * @param key - The key to delete the value for.
   */
  remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  /**
   * Deletes the values associated with the specified list of keys from local storage.
   * @param keys - List of keys to delete the value for.
   */
  removeKeys(keys: string[]): void {
    keys.forEach(key => this.localStorage.removeItem(key));
  }

  /**
   * Clears all values from local storage.
   */
  clear(): void {
    this.localStorage.clear();
  }

  private isJSONValid(value: string): boolean {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  }
}
