import { computed, inject } from '@angular/core';
import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import { map } from 'rxjs';
import { ApplicationState } from './application.store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { getNameInitials } from '@shared/utils';
import { toSignal } from '@angular/core/rxjs-interop';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
export const withComputedApplication = <_>() =>
  signalStoreFeature(
    type<{ state: ApplicationState }>(),
    withComputed((store, responsive = inject(BreakpointObserver)) => {
      const profile = store.userProfile;

      const language = computed(() => profile()?.info?.language);

      const userFirstName = computed(() => profile()?.info?.firstName);

      const userInitials = computed(() =>
        getNameInitials(profile()?.info?.firstName, profile()?.info?.lastName)
      );

      const isSelectedAccountUnconfirmed = computed(
        () => !!store.selectedAccount()?.unconfirmedCriticalInfo
      );

      const xsWidth = '(max-width: 599.98px)';
      const portrait = '(orientation: portrait)';
      const isMobile = toSignal(
        responsive
          .observe([xsWidth, portrait])
          .pipe(
            map(
              state => state.breakpoints[xsWidth] && state.breakpoints[portrait]
            )
          ),
        { initialValue: false, rejectErrors: true }
      );

      const smWidth = '(max-width: 959.98px)';
      const landscape = '(orientation: landscape)';
      const isMobileLandscape = toSignal(
        responsive
          .observe([smWidth, landscape])
          .pipe(
            map(
              state => state.breakpoints[xsWidth] && state.breakpoints[portrait]
            )
          ),
        { initialValue: false }
      );

      const xxxlWidth = '(min-width: 1900px)';
      const is2xl = toSignal(
        responsive
          .observe([xxxlWidth])
          .pipe(map(state => state.breakpoints[xxxlWidth])),
        { initialValue: false, rejectErrors: true }
      );

      return {
        // Application
        language,
        userFirstName,
        userInitials,
        isSelectedAccountUnconfirmed,
        isMobile,
        isMobileLandscape,
        is2xl
      };
    })
  );
