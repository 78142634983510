import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  NzNotificationDataOptions,
  NzNotificationService
} from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly nzNotification = inject(NzNotificationService);
  private readonly translateService = inject(TranslateService);

  /**
   * Display a information notification
   * @param message Information message (translates the message before displaying it)
   * @param msShowTime Number of ms to display the notification
   * @param title Title of the notification
   * @param options Additional options for the notification
   */
  info = (
    message: string,
    msShowTime?: number,
    title?: string,
    options?: NzNotificationDataOptions
  ): void => {
    this.createNotification('info', message, msShowTime, title, options);
  };

  /**
   * Display a success notification
   * @param message Success message (translates the message before displaying it)
   * @param msShowTime Number of ms to display the notification
   * @param title Title of the notification
   * @param options Additional options for the notification
   */
  success = (
    message: string,
    msShowTime?: number,
    title?: string,
    options?: NzNotificationDataOptions
  ): void => {
    this.createNotification('success', message, msShowTime, title, options);
  };

  /**
   * Display a error notification
   * @param message Error message (translates the message before displaying it)
   * @param msShowTime Number of ms to display the notification
   * @param title Title of the notification
   * @param options Additional options for the notification
   */
  error = (
    message: string,
    msShowTime?: number,
    title?: string,
    options?: NzNotificationDataOptions
  ): void => {
    this.createNotification('error', message, msShowTime, title, options);
  };

  /**
   * Display a error notification
   * @param message Warning message (translates the message before displaying it)
   * @param msShowTime Number of ms to display the notification
   * @param title Title of the notification
   * @param options Additional options for the notification
   */
  warning = (
    message: string,
    msShowTime?: number,
    title?: string,
    options?: NzNotificationDataOptions
  ): void => {
    this.createNotification('warning', message, msShowTime, title, options);
  };

  closeAll(): void {
    this.nzNotification.remove();
  }

  private createNotification(
    type: 'info' | 'success' | 'error' | 'warning',
    message: string | undefined,
    msShowTime = 2000,
    customTitle?: string,
    options?: NzNotificationDataOptions
  ): void {
    let title = customTitle ?? 'Information';
    let nzClass: string | null = null;

    switch (type) {
      case 'success':
        title = customTitle ?? 'notifications.successTitle';
        break;
      case 'error':
        title = customTitle ?? 'notifications.errorTitle';
        nzClass = 'notification error';
        msShowTime = 60000;
        break;
      case 'warning':
        title = customTitle ?? 'Heads up!';
        nzClass = 'notification warning';
        msShowTime = 5000;
        break;
    }

    this.nzNotification.create(
      type,
      this.translateService.instant(title),
      this.translateService.instant(message ?? ''),
      {
        nzDuration: msShowTime,
        nzClass,
        ...options
      }
    );
  }
}
