import { inject } from '@angular/core';
import {
  Account,
  AccountPermission,
  AccountRole,
  Team,
  TeamInfo,
  TeamRole,
  UserProfile
} from '@moxi.gmbh/moxi-typescriptmodels';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { LocalStorageService } from '@shared/services';
import { getAccountPermission, getAccountRoles } from '@shared/utils';
import { withComputedAuthentication } from './auth.feature';
import { withComputedApplication } from './application.feature';

export interface ApplicationState {
  userProfile: UserProfile;
  selectedAccount: Account;
  impersonator: string;
  pageTitle: string;
  pageSubTitle: string;
}

const initialState: ApplicationState = {
  userProfile: undefined,
  selectedAccount: undefined,
  impersonator: undefined,
  pageTitle: undefined,
  pageSubTitle: undefined
};

export const ApplicationStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withComputedApplication(),

  withComputedAuthentication(),

  withMethods((store, localStorageService = inject(LocalStorageService)) => {
    const setUserProfile = (userProfile: UserProfile): void => {
      if (!userProfile) {
        patchState(store, {
          userProfile: undefined,
          selectedAccount: undefined
        });

        localStorageService.removeKeys([
          'Authorization',
          'SessionDeadline',
          'impersonator'
        ]);
        return;
      }

      patchState(store, {
        userProfile: {
          ...store.userProfile(),
          ...userProfile
        },
        impersonator: localStorageService.get<string>('impersonator')
      });
    };

    const setAccount = (account: Account): void => {
      patchState(store, { selectedAccount: account });
    };

    const getUniqueAccountId = (): string =>
      store.userProfile()?.accountPermissions[0]?.accountId;

    const getUserAccountPermission = (
      user: UserProfile,
      accountId: string
    ): AccountPermission =>
      user?.accountPermissions.find(
        permissions => permissions.accountId === accountId
      );

    const getAllAccountTeams = (): Team[] => store.selectedAccount()?.teams;

    const getTeamInfo = (teamId: string): TeamInfo =>
      store.selectedAccount()?.teams.find(team => team.teamId === teamId)?.info;

    const getTeamsFromRole = (role: TeamRole): Team[] =>
      getAccountPermission(
        store.userProfile(),
        store.selectedAccount()?.accountId
      )
        ?.teamPermissions.filter(permission => permission.roles.includes(role))
        .map(team => ({
          teamId: team.teamId,
          info: getTeamInfo(team.teamId)
        }));

    const hasAnyAccountRole = (accountRoles: AccountRole[]): boolean =>
      accountRoles.some(role =>
        getAccountRoles(
          store.userProfile(),
          store.selectedAccount().accountId
        )?.includes(role)
      );

    const setPageTitle = (title = store.pageTitle()): void => {
      patchState(store, { pageTitle: title });
    };

    return {
      // User & Account
      setUserProfile,
      setAccount,
      getUniqueAccountId,

      // Authorization
      getUserAccountPermission,
      getAllAccountTeams,
      getTeamInfo,
      getTeamsFromRole,
      hasAnyAccountRole,

      // Application
      setPageTitle
    };
  })
);
