export const FLUX_API_CLASS = 'com.moxi.api';

/**
 * Flux API class types of all features
 */
export const ClassTypes = {
  account: `${FLUX_API_CLASS}.account`,
  ride: `${FLUX_API_CLASS}.ride`,
  rideseries: `${FLUX_API_CLASS}.rideseries`,
  permissions: `${FLUX_API_CLASS}.permissions`,
  integrations: `${FLUX_API_CLASS}.integrations`,
  token: `${FLUX_API_CLASS}.token`,
  statistics: `${FLUX_API_CLASS}.statistics`
} as const;
