import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from '@shared/services';
import { firstValueFrom, interval } from 'rxjs';

interface UpdateConfig {
  update: 'critical' | 'high' | 'low';
  timestamp: number | null;
}
interface NgSWConfig {
  timestamp: number | null;
}

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  private readonly notificationService = inject(NotificationService);
  private readonly updates = inject(SwUpdate);
  private readonly http = inject(HttpClient);
  private readonly document = inject(DOCUMENT);

  private version!: number;

  init(): void {
    const everyMinute$ = interval(60 * 1000);

    everyMinute$.subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        const config = await this.getUpdateConfig();

        if (updateFound) {
          this.updates.activateUpdate().then(() => this.updateAction(config));
          return;
        }

        const ngSwConfig = await this.getNgSWConfig();

        if (ngSwConfig.timestamp !== this.version) {
          this.updates.activateUpdate().then(() => this.updateAction(config));
          return;
        }
      } catch (err) {
        if (!isDevMode()) {
          console.error('Failed to check for updates:', err);
        }
      }
    });

    this.setVersionFromNgSWConfig();
  }

  private getUpdateConfig(): Promise<UpdateConfig> {
    const configFile = './assets/update.config.json';

    return firstValueFrom(this.http.get<UpdateConfig>(configFile));
  }

  private getNgSWConfig(): Promise<NgSWConfig> {
    const configFile = 'ngsw.json';

    return firstValueFrom(this.http.get<NgSWConfig>(configFile));
  }

  private setVersionFromNgSWConfig(): void {
    this.getNgSWConfig().then(config => (this.version = config.timestamp ?? 0));
  }

  private updateAction(config: UpdateConfig): void {
    switch (config.update) {
      case 'critical':
        this.document.location.reload();
        break;

      case 'high':
        {
          const title = 'notifications.updateService.title';
          const message = 'notifications.updateService.message';

          this.notificationService.closeAll();
          this.notificationService.info(message, 0, title, {
            nzClass: 'hide-notification-close-btn',
            nzStyle: {
              background: '#e6f4ff'
            }
          });
        }
        break;

      case 'low':
        // Don't take any action
        break;
    }
  }
}
