import { computed } from '@angular/core';
import { AccountRole } from '@moxi.gmbh/moxi-typescriptmodels';
import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import { ApplicationState } from './application.store';
import {
  hasAnyRole,
  hasAnyAccountRole as _hasAnyAccountRole,
  hasAnyTeamRole
} from '@shared/utils';
import { accountRolesInfo, RoleInfo, teamRolesInfo } from '@shared/models';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
export const withComputedAuthentication = <_>() =>
  signalStoreFeature(
    type<{ state: ApplicationState }>(),
    withComputed(store => {
      const profile = store.userProfile;
      const account = store.selectedAccount;
      const adminRoles: AccountRole[] = ['admin', 'owner', 'operator'];

      const isLoggedIn = computed(() => !!profile()?.userId);

      const isSuperAdmin = computed(() => profile()?.userRole === 'superadmin');

      const isOperator = computed(() => hasAnyRole(profile(), ['operator']));

      const isAdmin = computed(
        () =>
          isSuperAdmin() ||
          isOperator() ||
          _hasAnyAccountRole(profile(), account()?.accountId, adminRoles)
      );

      const isRideBookerWithoutTeam = computed(
        () =>
          isSuperAdmin() ||
          isOperator() ||
          _hasAnyAccountRole(profile(), account()?.accountId, [
            ...adminRoles,
            'ridebooker'
          ])
      );

      const isRideBooker = computed(
        () =>
          (isRideBookerWithoutTeam() ||
            hasAnyTeamRole(profile(), account()?.accountId, ['ridebooker']) ||
            hasAnyRole(profile(), ['ridebooker'])) &&
          hasRideBookingService()
      );

      const isDispatcherWithoutTeam = computed(
        () =>
          isSuperAdmin() ||
          isOperator() ||
          _hasAnyAccountRole(profile(), account()?.accountId, [
            ...adminRoles,
            'dispatcher'
          ])
      );

      const isDispatcher = computed(
        () =>
          (isDispatcherWithoutTeam() ||
            hasAnyTeamRole(profile(), account()?.accountId, ['dispatcher']) ||
            hasAnyRole(profile(), ['dispatcher'])) &&
          hasDispatcherService()
      );

      const hasRideBookingService = computed(
        () =>
          !!account()?.organisationTypes.find(
            organisation => organisation === 'medical'
          )
      );

      const hasDispatcherService = computed(
        () =>
          !!account()?.organisationTypes.find(
            organisation => organisation === 'fleet'
          )
      );

      const hasOperatorService = computed(
        () =>
          !!account()?.organisationTypes.find(
            organisation => organisation === 'operator'
          )
      );

      const hasCeliosIntegrationService = computed(
        () =>
          !!account()?.activeServices.find(
            service => service === 'celios_integration'
          )
      );

      const hasDispoliveIntegrationService = computed(
        () =>
          !!account()?.activeServices.find(
            service => service === 'dispolive_integration'
          )
      );

      const hasOnlyOneAccount = computed(
        () =>
          profile()?.accountPermissions?.length === 1 &&
          !isSuperAdmin() &&
          !isOperator()
      );

      const hasOnlyOperatorService = computed(
        () => account()?.organisationTypes?.length === 1 && hasOperatorService()
      );

      const getValidAccountRoles = computed(() => {
        const roleInfos = accountRolesInfo.filter(
          role => role.id === 'admin' || role.id === 'owner'
        );

        if (hasOperatorService()) {
          roleInfos.push(accountRolesInfo.find(role => role.id === 'operator'));
        }

        if (hasRideBookingService()) {
          roleInfos.push(
            accountRolesInfo.find(role => role.id === 'ridebooker')
          );
        }

        if (hasDispatcherService()) {
          roleInfos.push(
            accountRolesInfo.find(role => role.id === 'dispatcher')
          );
        }

        return roleInfos;
      });

      const getValidTeamRoles = computed(() => {
        const roleInfos: RoleInfo[] = [];

        if (hasRideBookingService()) {
          roleInfos.push(teamRolesInfo.find(role => role.id === 'ridebooker'));
        }

        if (hasDispatcherService()) {
          roleInfos.push(teamRolesInfo.find(role => role.id === 'dispatcher'));
        }

        return roleInfos;
      });

      return {
        // Authentication
        isLoggedIn,

        // Authorization
        isSuperAdmin,
        isOperator,
        isAdmin,
        isRideBookerWithoutTeam,
        isRideBooker,
        isDispatcherWithoutTeam,
        isDispatcher,
        hasRideBookingService,
        hasDispatcherService,
        hasCeliosIntegrationService,
        hasDispoliveIntegrationService,
        hasOperatorService,
        hasOnlyOneAccount,
        hasOnlyOperatorService,
        getValidAccountRoles,
        getValidTeamRoles
      };
    })
  );
